<template>
  <div class="article">
    <div class="article-header">
      <img src="@/images/newHome/resourceCentre/article-banner.png" alt="" />
      <div class="article-header-box">
        <div>
          <div class="banner-text">
            {{ $route.query.type === "1" ? "iLaw·实务文章" : "iLaw·直播" }}
          </div>
          <div class="banner-tips" v-if="$route.query.type === '1'">
            <div class="banner-con" v-for="item of bannerList" :key="item.id">
              <div class="tag-box">
                <div class="sub-title">{{ item.title }}</div>
                <div class="sub-detail">{{ item.detail }}</div>
              </div>
            </div>
          </div>
          <div class="banner-tips" v-else>
            <div class="live-broadcast-title">
              {{ "汇聚行业大咖，聚焦合规实务分享" }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article-main">
      <div class="title">
        {{ $route.query.type === "1" ? "iLaw文章" : "iLaw直播" }}
      </div>
      <div class="main-con">
        <div class="left-article">
          <div class="article-list" v-loading="loading">
            <div
              class="article-item"
              v-for="item of articleList"
              :key="item.aid"
              @click="openLink(item.link)"
            >
              <div
                v-if="$route.query.type === '1'"
                class="iframeBG"
                v-html="ImgFun(item.displayCover, 'bg')"
              ></div>
              <div
                v-else
                class="live-broadcast"
                v-html="ImgBroadcast(item.cover, 'bg')"
              ></div>
              <!-- <img :src="item.displayCover" alt="" /> -->
              <div class="left-con">
                <div class="name">
                  {{ item.title }}
                </div>
                <!-- <div class="detail">
                  {{ item.introduction }}
                </div> -->
                <div class="left-con-bottom">
                  <div class="date">
                    {{ item.articleUpdateTime.split(" ")[0] }}
                  </div>
                  <!-- <div class="tag">数据合规</div> -->
                </div>
              </div>
            </div>
          </div>
          <el-pagination
            style="text-align: center"
            background
            :current-page.sync="currentPage"
            :page-size="15"
            layout="prev, pager, next"
            :total="total"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
        <div class="btn-us-contact">
          <img src="../../images/newHome/resourceCentre/qr-code.png" />
          <div class="btn-us-contact-title">扫码联系圈圈，了解更多</div>
        </div>
      </div>
    </div>
    <footerCom></footerCom>
  </div>
</template>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<script>
import footerCom from "../../components/footerCom/index";
import api from "../../api/request";
export default {
  name: "articleIndex",
  components: { footerCom },
  mounted() {
    if (this.$route.query.type === "1") {
      this.getArticles();
    } else {
      this.getSupplement();
    }
  },
  data() {
    return {
      bannerList: [
        {
          id: 1,
          title: "11w+",
          detail: "法律行业粉丝",
        },
        {
          id: 2,
          title: "1150+",
          detail: "行业优质文章",
        },
        {
          id: 3,
          title: "350+",
          detail: "法律人创作投稿",
        },
      ],
      currentPage: 1,
      total: 0,
      articleList: [],
      loading: false,
    };
  },
  methods: {
    ImgBroadcast(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:120px;height:120px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="200px"></iframe>`;
      return iframeStr;
    },
    ImgFun(url, type) {
      let styles;
      let img;
      if (type === "bg") {
        img = "imgBg";
        styles = "width:250px;position:absolute;top:0;left:0;";
      }
      var randomId = img + url;
      window[`${img}${url}`] =
        '<img id="img" style=\'' +
        styles +
        "' src='" +
        url +
        "?" +
        "img" +
        "'/><script>window.onload = function() { parent.document.getElementById('" +
        randomId +
        "').height = 100+'%'; } <" +
        "/script>";
      var iframeStr = `<iframe id=${randomId} src="javascript:parent['${img}${url}']" frameBorder="0" scrolling="no" width="200px"></iframe>`;
      return iframeStr;
    },

    handleCurrentChange(val) {
      this.currentPage = val;
      if (this.$route.query.type === "1") {
        this.getArticles();
      } else {
        this.getSupplement();
      }
    },

    async getArticles() {
      this.loading = true;
      const params = {
        pageNum: this.currentPage,
        pageSize: 15,
      };
      const { code, msg, data } = await api.getArticles(params);
      if (code === 0) {
        console.log(data);
        this.articleList = data.list || [];
        this.total = data.total || 0;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    async getSupplement() {
      this.loading = true;
      const params = {
        pageNum: this.currentPage,
        pageSize: 15,
      };
      const { code, msg, data } = await api.getSupplement(params);
      if (code === 0) {
        console.log(data);
        this.articleList = data.list || [];
        this.total = data.total || 0;
      } else {
        this.$message.error(msg);
      }
      this.loading = false;
    },

    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
